import { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "../style/frisHoriz_v3.scss";
import { useTranslation } from "react-i18next";

const FriseHorizontale3 = () => {
  const { t } = useTranslation();
  const formationsContainerRef = useRef(null);
  const leftHoverZoneRef = useRef(null);
  const rightHoverZoneRef = useRef(null);
  const [leftHoverZoneClass, setLeftHoverZoneClass] = useState(
    "hover-zone hover-zone-left"
  );
  const [rightHoverZoneClass, setRightHoverZoneClass] = useState(
    "hover-zone hover-zone-right"
  );
  const [arrowsVisibility, setArrowsVisibility] = useState({
    left: false,
    right: true,
  });

  useEffect(() => {
    const scrollSpeed = 3;

    const handleMouseEnterLeft = () => {
      setLeftHoverZoneClass((prevState) => {
        if (arrowsVisibility.left) {
          return "hover-zone hover-zone-left fadeIn";
        } else {
          return prevState;
        }
      });
      const scrollInterval = setInterval(() => {
        if (formationsContainerRef.current) {
          formationsContainerRef.current.scrollLeft -= scrollSpeed;
        }
      }, 10);
      leftHoverZoneRef.current.dataset.scrollInterval = scrollInterval;
    };

    const handleMouseEnterRight = () => {
      setRightHoverZoneClass((prevState) => {
        if (arrowsVisibility.right) {
          return "hover-zone hover-zone-right fadeIn";
        } else {
          return prevState;
        }
      });
      const scrollInterval = setInterval(() => {
        if (formationsContainerRef.current) {
          formationsContainerRef.current.scrollLeft += scrollSpeed;
        }
      }, 10);
      rightHoverZoneRef.current.dataset.scrollInterval = scrollInterval;
    };

    const handleMouseLeaveLeft = (e) => {
      setLeftHoverZoneClass((prevState) => {
        if (arrowsVisibility.left) {
          return "hover-zone hover-zone-left fadeOut";
        } else {
          return prevState;
        }
      });
      clearInterval(e.target.dataset.scrollInterval);
    };

    const handleMouseLeaveRight = (e) => {
      setRightHoverZoneClass((prevState) => {
        if (arrowsVisibility.right) {
          return "hover-zone hover-zone-right fadeOut";
        } else {
          return prevState;
        }
      });
      clearInterval(e.target.dataset.scrollInterval);
    };

    leftHoverZoneRef.current.addEventListener(
      "mouseenter",
      handleMouseEnterLeft
    );
    leftHoverZoneRef.current.addEventListener(
      "mouseleave",
      handleMouseLeaveLeft
    );
    rightHoverZoneRef.current.addEventListener(
      "mouseenter",
      handleMouseEnterRight
    );
    rightHoverZoneRef.current.addEventListener(
      "mouseleave",
      handleMouseLeaveRight
    );

    return () => {
      if (leftHoverZoneRef.current) {
        leftHoverZoneRef.current.removeEventListener(
          "mouseenter",
          handleMouseEnterLeft
        );
        leftHoverZoneRef.current.removeEventListener(
          "mouseleave",
          handleMouseLeaveLeft
        );
      }
      if (rightHoverZoneRef.current) {
        rightHoverZoneRef.current.removeEventListener(
          "mouseenter",
          handleMouseEnterRight
        );
        rightHoverZoneRef.current.removeEventListener(
          "mouseleave",
          handleMouseLeaveRight
        );
      }
    };
  }, []);

  useEffect(() => {
    const updateArrowsVisibility = () => {
      const container = formationsContainerRef.current;
      const newArrowsVisibility = {
        left: container.scrollLeft !== 0,
        right:
          container.scrollLeft + container.clientWidth < container.scrollWidth,
      };

      setArrowsVisibility(newArrowsVisibility);
    };

    updateArrowsVisibility();

    const handleScroll = () => {
      updateArrowsVisibility();
    };

    formationsContainerRef.current.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateArrowsVisibility);

    return () => {
      if (formationsContainerRef.current) {
        formationsContainerRef.current.removeEventListener(
          "scroll",
          handleScroll
        );
      }
      window.removeEventListener("resize", updateArrowsVisibility);
    };
  }, []);

  return (
    <Container className="formations-wrapper">
      <div
        ref={leftHoverZoneRef}
        className={`hover-zone hover-zone-left ${
          arrowsVisibility.left ? "fadeIn" : "fadeOut"
        }`}
      >
        <div className="arrow arrow-left" />
      </div>
      <div
        ref={rightHoverZoneRef}
        className={`hover-zone hover-zone-right ${
          arrowsVisibility.right ? "fadeIn" : "fadeOut"
        }`}
      >
        <div className="arrow arrow-right" />
      </div>
      <div className="formations-container" ref={formationsContainerRef}>
        <Row>
          <Col>
            <div className="timeline">
              {t(`pages.about.blocs.0.data`, { returnObjects: true }).map(
                (info, index) => (
                  <div
                    key={info.id + "-" + info.title}
                    className="timeline-item"
                    style={{
                      // borderLeftStyle: info.lineStyle,
                      // borderLeftColor: info.lineColor,
                      borderLeftWidth: info.lineWidth,
                      paddingLeft: index === 0 ? 0 : "30px", // Ajoutez ceci pour éviter un décalage sur le premier élément
                    }}
                  >
                    <Card className="mb-4" key={info.id + "-" + info.title}>
                      <Card.Body className="h-100 d-flex flex-column form-horiz-cbody">
                        <span>
                          {" "}
                          <Card.Title>{info.title}</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">
                            {info.institution}
                          </Card.Subtitle>
                          <Card.Text className="flex-grow-1">
                            {info.description}
                          </Card.Text>
                        </span>
                      </Card.Body>
                      <Card.Footer className="text-muted">
                        <span>{info.date}</span>
                        <span
                          className={info.degree != "" ? "degree-info" : <></>}
                        >
                          {info.degree != " " ? info.degree : <></>}
                        </span>
                      </Card.Footer>
                    </Card>
                  </div>
                )
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default FriseHorizontale3;
