import React, { useEffect, useState } from "react";
import "../style/about.scss";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Container, Row, Col } from "react-bootstrap";
import FriseHorizontale3 from "../components/friseHorz_v3";
import FriseVerticalV4 from "../components/friseVert_v4";
import FriseVerticalV5 from "../components/friseVert_v5";
import Globe2 from "../components/Globe_2";
import Interets2 from "../components/interets";
import useIntersectionObserver from "../components/effects/useIntersectionObserver";
import { useTranslation } from "react-i18next";
import { customIcons } from "../config/icons";

function About() {
  const observe = useIntersectionObserver();
  const { t } = useTranslation();

  function tooltip(txt) {
    return (
      <Tooltip className="tooltips-lang" key={txt}>
        <strong>{txt}</strong>
      </Tooltip>
    );
  }

  return (
    <Container className="about opacity-effect">
      <Row className="about-blocs xps" ref={observe}>
        <h5 className="about-title">{t(`pages.about.blocs.0.title`)}</h5>
        <FriseHorizontale3 />
        <hr className="about-lines" />
      </Row>
      <Row className="about-blocs formations" ref={observe}>
        <h5 className="about-title">{t(`pages.about.blocs.1.title`)}</h5>
        <FriseVerticalV5 />
        <hr className="about-lines" />
      </Row>
      <Row className="about-blocs" ref={observe}>
        <Col>
          <h5 className="about-title">
            {t(`pages.about.blocs.2.blocs.0.title`)}
          </h5>
          <Row className="flags-list fadeInAnim">
            {t(`pages.about.blocs.2.blocs.0.data`, {
              returnObjects: true,
            }).map((flag, index) => (
              <div style={{ maxWidth: "40%" }} key={`da${index}`}>
                <OverlayTrigger
                  placement={index < 2 ? "top" : "bottom"}
                  overlay={tooltip(flag.title)}
                  key={index + "flag"}
                  zIndex={1000}
                >
                  <span style={{ width: "auto" }} key={index}>
                    {customIcons.langues[flag.code]?.icon}
                  </span>
                </OverlayTrigger>
              </div>
            ))}
          </Row>
        </Col>
        <hr className="about-lines about-lines-2" />
        <div style={{ width: "1px", borderLeft: "1px solid #80808042" }}></div>
        <Col>
          <h5 className="about-title">
            {t(`pages.about.blocs.2.blocs.1.title`)}
          </h5>
          <Interets2 />
        </Col>
        <div style={{ width: "1px", borderLeft: "1px solid #80808042" }}></div>
        <hr className="about-lines about-lines-2" />
        <Col>
          <h5 className="about-title">
            {t(`pages.about.blocs.2.blocs.2.title`)}
          </h5>
          <Globe2 />
        </Col>
      </Row>
    </Container>
  );
}

export default About;
