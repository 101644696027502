import "./style/App.scss";
import "./style/mobile.scss";
import "./style/effects.scss";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ReactGA from "react-ga4";

import { router } from "./config/router";
import GA4utils from "./services/GA4";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    GA4utils.InitializeGA4();
  }, []);

  return (
    <div className="App">
      <Header />
      <Routes>
        {router.map((route, index) => (
          <Route key={index} path={route.url} element={route.component} />
        ))}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
