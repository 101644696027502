import ReactGA from "react-ga4";

const TRACKING_ID = "G-BS5JCZ2YD2"; // your Measurement ID

const GA4utils = {
  InitializeGA4: () => {
    ReactGA.initialize(TRACKING_ID);
  },
  ViewPage: (page, title) => {
    ReactGA.send({ hitType: "pageview", page: page, title: title });
  },
  handleClick: (platform) => {
    ReactGA.event({
      category: "Contact Links",
      action: "Click",
      label: platform,
    });
  },
};

export default GA4utils;
