import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { customIcons } from "../config/icons";

export default function ProjectStack({ projectId, stack }) {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id={`tooltip-${projectId}-${stack}`}>
          {customIcons?.languages[stack]?.props?.alt}
        </Tooltip>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <span key={projectId + "-" + stack} ref={ref} {...triggerHandler}>
          {customIcons.languages[stack]}
        </span>
      )}
    </OverlayTrigger>
  );
}
