import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Richard from "../assets/richard-modif-2.png";
import Richard2 from "../assets/Richard_2023.jpg";
import Richard3 from "../assets/Richard_2023_2.png";
import Richard3L from "../assets/Richard_2023_2_light.png";

import "../style/Home.scss";
import TypeWritingEffect2 from "../components/TypeWritingEffect_styled";

function Home() {
  const videoRef = useRef(null);
  const [height, setHeight] = useState(400);

  useEffect(() => {
    let timeout;

    // handles setting the iframe's height on window resize to maintain aspect ratio
    const updateSize = () => {
      if (!videoRef?.current) return;

      clearTimeout(timeout);

      timeout = setTimeout(() => {
        setHeight(videoRef?.current?.clientWidth / (16 / 9));
      }, 100);
    };

    updateSize();

    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [videoRef]);

  return (
    <Container className="home-page-rb">
      <Row className="home-row-bloc">
        <Col className="home-img slide-in-left">
          <div>
            <img className="riri" src={Richard3L} alt="Richard Bruzzo" />
          </div>
        </Col>
        <Col className="text-justify home-main-txt slide-in-right">
          <TypeWritingEffect2 />
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
