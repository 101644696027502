import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importation des fichiers de traduction
import translationEN from "./data/en/translation.json";
import translationFR from "./data/fr/translation.json";

// Les ressources de traduction
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fr", // Langue par défaut
  fallbackLng: "en", // Langue de secours

  interpolation: {
    escapeValue: false, // React gère déjà l'échappement des valeurs
  },
});

export default i18n;
