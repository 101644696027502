import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/esm/Row";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { customIcons } from "../config/icons";

function StacksDev() {
  const { t } = useTranslation();

  function toCard(skill) {
    return (
      <Card key={`card-${skill.name}`} className="card-space">
        <Card.Body className="stacksCB">
          <Card.Img
            className="cardImgs"
            variant="left"
            src={customIcons.stacks[skill.image]?.image}
            alt={customIcons.stacks[skill.image]?.name}
          />
          <span className="stacksTXT">
            <Card.Title key={`title-${skill.name}`}>{skill.name}</Card.Title>
            <Card.Text>
              {skill.categories.map((stack) => {
                return <span key={`${skill.name}-${stack}`}>{stack}</span>;
              })}
            </Card.Text>
          </span>
        </Card.Body>
      </Card>
    );
  }

  const displayData = (newData) => {
    return newData?.map((element) => {
      return element?.map((fmd) => {
        return toCard(fmd);
      });
    });
  };

  const [dataDisplayed, setDataDisplayed] = useState(
    displayData([
      Object.values(
        t(`pages.stacks.data.data.0.data.languages.data`, {
          returnObjects: true,
        })
      ),
      Object.values(
        t(`pages.stacks.data.data.0.data.frameworks.data`, {
          returnObjects: true,
        })
      ),
      Object.values(
        t(`pages.stacks.data.data.0.data.others.data`, {
          returnObjects: true,
        })
      ),
    ])
  );

  function filterData(filter) {
    t(`pages.stacks.data.data.0.categories`, {
      returnObjects: true,
    }).map((cat) => {
      document
        .getElementById(`btn-${cat.name}`)
        .classList.remove("filter-active");
    });
    document.getElementById(`btn-All`).classList.remove("filter-active");
    document.getElementById(`btn-${filter}`).classList.add("filter-active");
    let filteredData = [];

    if (filter === "All") {
      setDataDisplayed(
        displayData([
          Object.values(
            t(`pages.stacks.data.data.0.data.languages.data`, {
              returnObjects: true,
            })
          ),
          Object.values(
            t(`pages.stacks.data.data.0.data.frameworks.data`, {
              returnObjects: true,
            })
          ),
          Object.values(
            t(`pages.stacks.data.data.0.data.others.data`, {
              returnObjects: true,
            })
          ),
        ])
      );
      return;
    }

    let newFilter = t(`pages.stacks.data.data.0.categories`, {
      returnObjects: true,
    }).find((cat) => cat.name === filter).categories;

    [
      Object.values(
        t(`pages.stacks.data.data.0.data.languages.data`, {
          returnObjects: true,
        })
      ),
      Object.values(
        t(`pages.stacks.data.data.0.data.frameworks.data`, {
          returnObjects: true,
        })
      ),
      Object.values(
        t(`pages.stacks.data.data.0.data.others.data`, {
          returnObjects: true,
        })
      ),
    ].map((stack) => {
      let filteredStack = [];
      stack.map((skill) => {
        let matched = false;
        newFilter.map((cat) => {
          skill.categories.includes(cat) ? (matched = true) : <></>;
        });
        matched ? filteredStack.push(skill) : <></>;
      });
      filteredData.push(filteredStack);
    });
    setDataDisplayed(displayData(filteredData));
  }

  function btnFilters(input) {
    let classT = input === "All" ? "filter-active" : "";
    return (
      <Button
        className={`btnFilter ${classT}`}
        variant="outline-secondary"
        onClick={() => filterData(input)}
        id={`btn-${input}`}
        key={input}
      >
        {input}
      </Button>
    );
  }

  return (
    <span>
      <Row className="filtersData">
        {btnFilters("All")}
        {t(`pages.stacks.data.data.0.categories`, {
          returnObjects: true,
        }).map((cat) => {
          return btnFilters(cat.name);
        })}
      </Row>
      <Row className="stacksDiv">{dataDisplayed}</Row>
    </span>
  );
}

export default StacksDev;
