import { useEffect, useRef, useState } from "react";
import { Card, Container } from "react-bootstrap";
import "../style/frizVert.scss";
import { useTranslation } from "react-i18next";

const FriseVerticalV5 = () => {
  const { t } = useTranslation();

  const timelineItems = useRef([]);
  const containerRef = useRef(null);
  const [visibleItems, setVisibleItems] = useState(new Set()); // Pour stocker les indices des éléments visibles

  function dataSplit(data) {
    return data.split("\n");
  }

  function splitAndTruncateDescCustom(desc, length, phrasesNb) {
    let resp = [];
    const lines = desc.split("\n");

    for (let i = 0; i < phrasesNb; i++) {
      const line = lines[i] || "";
      const truncatedLine =
        line.length > length ? line.substring(0, length) + "..." : line;
      resp.push(truncatedLine);
    }

    const restOfLines = lines.slice(phrasesNb);

    return { resp, restOfLines };
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = timelineItems.current.indexOf(entry.target);
          if (entry.isIntersecting) {
            setVisibleItems((prev) => new Set(prev.add(index)));
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    timelineItems.current.forEach((item) => {
      if (item) observer.observe(item);
    });

    return () => {
      timelineItems.current.forEach((item) => {
        if (item) observer.unobserve(item);
      });
    };
  }, []);

  return (
    <Container
      fluid
      className={`formations-vert-container v3`}
      ref={containerRef}
    >
      <div className="timeline">
        {t(`pages.about.blocs.1.data`, { returnObjects: true }).map(
          (formation, index) => (
            <div
              ref={(el) => (timelineItems.current[index] = el)}
              key={formation.id}
              className={`timeline-item ${
                index % 2 === 0 ? "toLeft" : "toRight"
              } ${visibleItems.has(index) ? "visible" : ""}`}
            >
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>{formation.title}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {formation.institution}
                  </Card.Subtitle>
                  {/* <Card.Text> */}
                  {/* <div className="mt-0">
                  {splitAndTruncateDescCustom(formation.description).resp}
                  <br />
                  {splitAndTruncateDescCustom(
                    formation.description
                  ).restOfLines.map((line, idrl) => (
                    <div className="truncated-text me-3" key={idrl}>
                      {line}
                    </div>
                  ))}
                </div> */}
                  <div className="mt-3">
                    {dataSplit(formation.description).map((line, idrl) => (
                      <div className="truncated-text me-3" key={idrl}>
                        {line}
                      </div>
                    ))}
                  </div>
                  {/* </Card.Text> */}
                </Card.Body>
                <Card.Footer className="text-muted">
                  <span>{formation.date}</span>
                  <span>{formation.durée}</span>
                </Card.Footer>
              </Card>
            </div>
          )
        )}
      </div>
    </Container>
  );
};

export default FriseVerticalV5;
