import Dropdown from "react-bootstrap/Dropdown";
import { ListUl } from "react-bootstrap-icons";

import { useLocation } from "react-router-dom";
import { router } from "../config/router";
import { useTranslation } from "react-i18next";

function BurgerMenu() {
  const { t } = useTranslation();
  const location = useLocation();

  function routerNavBurger() {
    return (
      <>
        {router.map((item, index) => {
          return (
            <Dropdown.Item
              href={item.url}
              key={`item${index}`}
              className={`links ${
                location.pathname === item.url ? "active" : ""
              }`}
            >
              {item.icon} {item.name ? t(`pages.${item.name}.nav`) : <></>}
            </Dropdown.Item>
          );
        })}
      </>
    );
  }

  return (
    <Dropdown className="burger-menu" color="red">
      <Dropdown.Toggle id="dropdown-burger">
        <ListUl size={28} alt="Burger Menu" aria-label="Burger Menu" />
      </Dropdown.Toggle>

      <Dropdown.Menu>{routerNavBurger()}</Dropdown.Menu>
    </Dropdown>
  );
}

export default BurgerMenu;
