import "../style/darkmode.scss";
import { useState, useEffect } from "react";
import { BrightnessHighFill, MoonFill } from "react-bootstrap-icons";

const DarkMode = () => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "none");

  const setDark = () => {
    localStorage.setItem("theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
    setTheme("dark");
  };

  const setLight = () => {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
    setTheme("light");
  };

  const storedTheme = localStorage.getItem("theme");

  const prefersDark =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  const defaultDark =
    storedTheme === "dark" || (storedTheme === null && prefersDark);

  useEffect(() => {
    if (defaultDark) {
      setDark();
    }
  }, []);

  return (
    <div className="toggle-theme-wrapper zoom-in-hard">
      {theme === "light" ? (
        <span className="menu-item dark-icn" onClick={() => setDark()}>
          <MoonFill size={15} color={"#1f446f"} />
        </span>
      ) : (
        <span className="menu-item dark-icn" onClick={() => setLight()}>
          <BrightnessHighFill size={18} color={"orange"} />
        </span>
      )}
    </div>
  );
};

export default DarkMode;
