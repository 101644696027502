import React from "react";
import { useTranslation } from "react-i18next";
import StacksDev2 from "../components/StacksDev";
import StacksTools2 from "../components/StacksTools";
import "../style/stacks.scss";
import Container from "react-bootstrap/esm/Container";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function Stacks() {
  const { t } = useTranslation();

  return (
    <Container className="main-content stacks-page opacity-effect">
      <h1 className="opacity-effect">{t(`pages.stacks.title`)}</h1>
      <Tabs
        defaultActiveKey="dev"
        id="stacks-tabs"
        className="mb-3 opacity-effect"
        justify
      >
        <Tab
          id="tabs-titles-1"
          eventKey="dev"
          title={`${t(`pages.stacks.data.data.0.title`)}`}
          className="slide-in-bottom"
        >
          <StacksDev2 />
        </Tab>
        <Tab
          className="tabs-titles slide-in-bottom"
          eventKey="tools"
          title={`${t(`pages.stacks.data.data.1.title`)}`}
        >
          <StacksTools2 />
        </Tab>
      </Tabs>
    </Container>
  );
}

export default Stacks;
