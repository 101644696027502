import React from "react";
import Container from "react-bootstrap/esm/Container";
import Card from "react-bootstrap/Card";
import "../style/projects.scss";
import Row from "react-bootstrap/esm/Row";
import { Badge, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import ProjectStack2 from "../components/projectStack";
import { customIcons } from "../config/icons";
import { useTranslation } from "react-i18next";
import utils from "../services/utils";

function Projects() {
  const { t } = useTranslation();

  return (
    <Container className="main-content opacity-effect">
      <h1 className="opacity-effect">{t(`pages.projects.title`)}</h1>

      <Row className="projects slide-in-bottom">
        {t(`pages.projects.data`, { returnObjects: true })
          ?.reverse()
          .map((project) => {
            return (
              <Card
                key={project.id}
                style={project.image ? {} : { alignSelf: "baseline" }}
              >
                <Badge
                  className="badge-projects"
                  bg="info"
                  text="dark"
                  pill
                  style={
                    project.image
                      ? {
                          width: "fit-content",
                          position: "absolute",
                          top: "22px",
                          left: "20px",
                          fontWeight: "500",
                        }
                      : {
                          width: "fit-content",
                          position: "relative",
                          top: "10px",
                          left: "14px",
                          fontWeight: "500",
                        }
                  }
                >
                  {project.src}
                </Badge>
                {project.image ? (
                  <Card.Img
                    variant="top"
                    src={customIcons.projects[project.image]?.img}
                    alt={project.name}
                    key={"img" + project.id}
                  />
                ) : (
                  <></>
                )}
                <Card.Body key={"body" + project.id}>
                  <Card.Title key={"ctl" + project.id} as={Row}>
                    <Col className="h5"> {project.name}</Col>
                    <Col xs="auto">
                      {project.support.map((support, index) => (
                        <OverlayTrigger
                          key={"ot-" + project.id + "-" + index}
                          placement="bottom"
                          overlay={
                            <Tooltip
                              id={`tooltip-${project.id}-projects`}
                              key={"tt-" + project.id + "-" + index}
                            >
                              {utils.Capitalize(support.title)}
                            </Tooltip>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <span
                              key={project.id + "-projects"}
                              ref={ref}
                              {...triggerHandler}
                            >
                              {customIcons.icons[support.icon]}
                            </span>
                          )}
                        </OverlayTrigger>
                      ))}
                    </Col>
                  </Card.Title>
                  <Card.Text key={"ctt" + project.id}>
                    {project.description}
                  </Card.Text>
                </Card.Body>
                <Card.Footer
                  className="text-muted card-footer-div"
                  key={"cf" + project.id}
                >
                  <Col xs={2} key={"pd" + project.id}>
                    {project.date}
                  </Col>
                  <Col className="projects-stacks" key={"ps" + project.id}>
                    {[...project.stacks].reverse().map((stack, index) => (
                      <ProjectStack2
                        key={project.id + "-" + index}
                        projectId={project.id}
                        stack={stack}
                      />
                    ))}
                  </Col>
                </Card.Footer>
              </Card>
            );
          })}
      </Row>
    </Container>
  );
}

export default Projects;
