import { useEffect, useRef } from "react";

function useIntersectionObserver() {
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // console.log("Element is visible");
            entry.target.classList.add("fadeInAnim");
          } else {
            // Optionnel: retirer l'animation si l'élément sort du viewport
            entry.target.classList.remove("fadeInAnim");
          }
        });
      },
      {
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const currentObserver = observer.current;
    return () => {
      // Assure-toi de déconnecter l'observateur pour éviter des fuites de mémoire
      currentObserver.disconnect();
    };
  }, []);

  const observe = (node) => {
    // Assure-toi que l'observateur est prêt et que le nœud existe avant d'observer
    if (node && observer.current) {
      observer.current.observe(node);
    }
  };

  return observe;
}

export default useIntersectionObserver;
