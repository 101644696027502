import { OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { customIcons } from "../config/icons";

function Interets() {
  const { t } = useTranslation();

  function tooltip(txt) {
    return (
      <Tooltip className="tooltips-ints">
        <strong>{txt}</strong>
      </Tooltip>
    );
  }

  const DisplayInterets = (newData) => {
    return newData.map((fmd, index) => (
      <span key={"spanOT-" + index}>
        <OverlayTrigger
          placement={fmd.ttplacement}
          overlay={tooltip(fmd.title)}
          key={"OT-" + index}
        >
          <img
            src={customIcons.interets[fmd.icon]}
            alt={fmd.title}
            key={"imgOT-" + index}
          />
        </OverlayTrigger>
      </span>
    ));
  };

  return (
    <Row id="interets-row-ics">
      {DisplayInterets(
        t(`pages.about.blocs.2.blocs.1.data`, { returnObjects: true })
      )}
    </Row>
  );
}

export default Interets;
