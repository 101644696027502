import React, { useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import useTextChangesObserver from "./effects/textChanges";
import { useTranslation } from "react-i18next";
import i18n from "../config/i18n";

function TypeWritingEffectV2() {
  const { t } = useTranslation();
  const [dataTxt] = useState(
    t(`pages.home.data`, {
      returnObjects: true,
    })
  );
  const [key, setKey] = useState(0);

  // useTextChangesObserver();

  useEffect(() => {
    // Change the key to force a rerender
    setKey((prevKey) => prevKey + 1);
  }, [i18n.language, t]);

  return (
    <div>
      {dataTxt && (
        <TypeAnimation
          key={key}
          className="type-animation-blc"
          style={{ whiteSpace: "pre-line", height: "195px", display: "block" }}
          id="rb-typing-txt"
          sequence={t(`pages.home.data`, {
            returnObjects: true,
          })}
          repeat={0}
          omitDeletionAnimation={true}
        />
      )}
    </div>
  );
}

export default TypeWritingEffectV2;
