import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Marker,
  Tooltip,
  LayersControl,
  LayerGroup,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import customPin from "../assets/about/icons/accueil_3.png";
import { useTranslation } from "react-i18next";
import i18n from "../config/i18n";

function Globe() {
  const { t } = useTranslation();
  const [key, setKey] = useState(0);
  const [geojsonDataLived, setGeojsonDataLived] = useState(null);
  const [geojsonDataVisited, setGeojsonDataVisited] = useState(null);
  const [geojsonDataSoon, setGeojsonDataSoon] = useState(null);
  const [markerSize, setMarkerSize] = useState({ width: 15, height: 15 });
  const [mapPosition, setMapPosition] = useState(
    t(`pages.about.blocs.2.blocs.2.default.all.center`, {
      returnObjects: true,
    })
  );
  const [mapZoom, setMapZoom] = useState(
    t(`pages.about.blocs.2.blocs.2.default.all.zoom`, {
      returnObjects: true,
    })
  );

  const iconUrl = customPin;
  const icon = L.icon({
    iconUrl: iconUrl,
    iconSize: [markerSize.width, markerSize.height],
  });

  const handleResize = () => {
    const screenWidth = window.innerWidth;
    const markerWidth = screenWidth * 0.007; // Ajustez cette valeur selon vos besoins
    const markerHeight = (markerWidth / 25) * 41; // Calculer la hauteur proportionnelle

    if (screenWidth < 1500) {
      setMapPosition(
        t(`pages.about.blocs.2.blocs.2.default.small.center`, {
          returnObjects: true,
        })
      ); // Position de la France
      setMapZoom(
        t(`pages.about.blocs.2.blocs.2.default.small.zoom`, {
          returnObjects: true,
        })
      ); // Niveau de zoom de la France
    }
  };

  async function fetchData(countryCodes, setData) {
    try {
      const data = await Promise.all(
        countryCodes.map((code) =>
          fetch(
            `https://raw.githubusercontent.com/johan/world.geo.json/master/countries/${code}.geo.json`
          ).then((response) => response.json())
        )
      );
      const dataObj = countryCodes.reduce(
        (obj, code, i) => ({ ...obj, [code]: data[i] }),
        {}
      );

      setData(dataObj);
    } catch (error) {
      console.error("Error fetching GeoJSON data:", error);
    }
  }

  useEffect(() => {
    fetchData(
      t(`pages.about.blocs.2.blocs.2.data.lived`, {
        returnObjects: true,
      }),
      setGeojsonDataLived
    );
    fetchData(
      t(`pages.about.blocs.2.blocs.2.data.visited`, {
        returnObjects: true,
      }),
      setGeojsonDataVisited
    );
    fetchData(
      t(`pages.about.blocs.2.blocs.2.data.soon`, {
        returnObjects: true,
      }),
      setGeojsonDataSoon
    );
  }, [t, i18n.language]);

  useEffect(() => {
    handleResize(); // Appeler la fonction pour définir la taille initiale
    window.addEventListener("resize", handleResize); // Écouter les changements de taille de l'écran

    return () => {
      window.removeEventListener("resize", handleResize); // Nettoyer l'écouteur d'événement lors du démontage du composant
    };
  }, []);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [t, i18n.language]);

  return (
    <div>
      <MapContainer
        key={key}
        center={mapPosition}
        zoom={mapZoom}
        style={{ height: "23vh", width: "100%" }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <LayersControl position="topright">
          {geojsonDataLived && (
            <LayersControl.Overlay
              checked
              name={`${t(`pages.about.blocs.2.blocs.2.layers.0.title`)}`}
              key={0}
            >
              <LayerGroup key={"LG" + 0}>
                {Object.entries(geojsonDataLived).map(
                  ([countryName, geojson]) => (
                    <GeoJSON
                      key={countryName}
                      data={geojson}
                      style={t(`pages.about.blocs.2.blocs.2.style.lived`, {
                        returnObjects: true,
                      })}
                    />
                  )
                )}
                {Object.entries(
                  t(`pages.about.blocs.2.blocs.2.data.cities`, {
                    returnObjects: true,
                  })
                ).map(([cityName, position]) => (
                  <Marker key={cityName} position={position} icon={icon}>
                    <Tooltip>
                      {cityName.charAt(0).toUpperCase() + cityName.slice(1)}
                    </Tooltip>
                  </Marker>
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          )}
          {geojsonDataVisited && (
            <LayersControl.Overlay
              checked
              name={`${t(`pages.about.blocs.2.blocs.2.layers.1.title`)}`}
              key={1}
            >
              <LayerGroup key={"LG" + 1}>
                {Object.entries(geojsonDataVisited).map(
                  ([countryName, geojson]) => (
                    <GeoJSON
                      key={countryName}
                      data={geojson}
                      style={t(`pages.about.blocs.2.blocs.2.style.visited`, {
                        returnObjects: true,
                      })}
                    />
                  )
                )}
              </LayerGroup>
            </LayersControl.Overlay>
          )}
          {geojsonDataSoon && (
            <LayersControl.Overlay
              checked
              name={`${t(`pages.about.blocs.2.blocs.2.layers.2.title`)}`}
              key={2}
            >
              <LayerGroup key={"LG" + 2}>
                {Object.entries(geojsonDataSoon).map(
                  ([countryName, geojson]) => (
                    <GeoJSON
                      key={countryName}
                      data={geojson}
                      style={t(`pages.about.blocs.2.blocs.2.style.soon`, {
                        returnObjects: true,
                      })}
                    />
                  )
                )}
              </LayerGroup>
            </LayersControl.Overlay>
          )}
        </LayersControl>
      </MapContainer>
    </div>
  );
}

export default Globe;
