import { Globe2, Phone, Laptop } from "react-bootstrap-icons";
import { ES, FR, IT, US } from "country-flag-icons/react/3x2";
import avionGif from "../assets/about/gifs/avion.gif";
import htmlGif from "../assets/about/gifs/html.gif";
import motoGif from "../assets/about/gifs/moto.gif";
import printerGif from "../assets/about/gifs/imprimante-3d-2.png";
import equipementGif from "../assets/about/gifs/equipement.gif";
import office from "../assets/stacks/office.png";
import fusion from "../assets/stacks/fusion.png";
import premierepro from "../assets/stacks/premierepro.svg";
import indesign from "../assets/stacks/indesign.webp";
import illustrator from "../assets/stacks/illustrator.png";
import photoshop from "../assets/stacks/photoshop.webp";
import slack from "../assets/stacks/slack.svg";
import zapier from "../assets/stacks/zapier.svg";
import trello from "../assets/stacks/trello.svg";
import vscode from "../assets/stacks/vscode.svg";
import visualstudio from "../assets/stacks/visualstudio.svg";
import proxmox from "../assets/stacks/proxmox.png";
import analytics from "../assets/stacks/analytics.png";
import primereact from "../assets/stacks/primereact.png";
import PrimeReact from "../assets/stacks/primereact-1.svg";
import atera from "../assets/stacks/atera.png";
import teamviewer from "../assets/stacks/teamviewer.png";
import mailjet from "../assets/stacks/mailjet.png";
import portfolio from "../assets/projects/portfolio.png";
import AppLisaGestion from "../assets/projects/lisagestion.png";
import pictMan0 from "../assets/projects/pictManager/0.png";
import pictMan1 from "../assets/projects/pictManager/1.png";
import pictMan1L from "../assets/projects/pictManager/1_light.png";
import adaptia1 from "../assets/projects/adapt-ia/adaptia_1.png";
import adaptia1L from "../assets/projects/adapt-ia/adaptia_1_light.png";

const customIcons = {
  languages: {
    // -------------------- Frontend --------------------
    javascript: (
      <i
        className="devicon-javascript-plain colored"
        key={"devicon-javascript-plain"}
        alt="JavaScript"
      ></i>
    ),
    typescript: (
      <i
        className="devicon-typescript-plain colored"
        key="devicon-typescript-plain colored"
        alt="TypeScript"
      ></i>
    ),
    reactjs: (
      <i
        className="devicon-react-original colored"
        key={"devicon-react-plain"}
        alt="ReactJS"
      ></i>
    ),
    vuejs: (
      <i
        className="devicon-vuejs-plain colored"
        key="devicon-vuejs-plain colored"
        alt="VueJS"
      ></i>
    ),
    csharp: (
      <i
        className="devicon-csharp-plain colored"
        key="devicon-csharp-plain colored"
        alt="C#"
      ></i>
    ),
    dotnet: (
      <i
        className="devicon-dot-net-plain-wordmark"
        key="devicon-dot-net-plain-wordmark"
        alt=".NET"
      ></i>
    ),

    // -------------------- BackEnd --------------------
    python: (
      <i
        className="devicon-python-plain"
        key="devicon-python-plain"
        alt="Python"
      ></i>
    ),
    nodejs: (
      <i
        className="devicon-nodejs-plain-wordmark"
        key={"devicon-nodejs-plain"}
        alt="NodeJS"
      ></i>
    ),
    expressjs: (
      <i
        className="devicon-express-original colored"
        key="devicon-express-original colored"
        alt="ExpressJS"
      ></i>
    ),
    php: (
      <i
        className="devicon-php-plain colored"
        key="devicon-php-plain colored"
        alt="PHP"
      ></i>
    ),
    symfony: (
      <i
        className="devicon-symfony-original"
        key="devicon-symfony-original-wordmark"
        alt="Symfony"
      ></i>
    ),
    fastapi: (
      <i
        className="devicon-fastapi-plain colored"
        key={"devicon-fastapi-plain"}
        alt="FastAPI"
      ></i>
    ),
    flask: (
      <i
        className="devicon-flask-original"
        key={"devicon-flask-original"}
        alt="Flask"
      ></i>
    ),
    elixir: (
      <i
        className="devicon-elixir-plain colored"
        key="devicon-elixir-plain colored"
        alt="Elixir"
      ></i>
    ),

    // -------------------- Mobile --------------------

    ionic: (
      <i
        className="devicon-ionic-original colored"
        key="devicon-ionic-original colored"
        alt="Ionic"
      ></i>
    ),
    flutter: (
      <i
        className="devicon-flutter-plain colored"
        key="devicon-flutter-plain colored"
        alt="Flutter"
      ></i>
    ),
    dart: (
      <i
        class="devicon-dart-plain colored"
        key="devicon-dart-plain colored"
        alt="Dart"
      ></i>
    ),

    // -------------------- DB --------------------

    mysql: (
      <i
        className="devicon-mysql-original colored"
        key={"devicon-mysql-original"}
        alt="MySQL"
      ></i>
    ),
    postgresql: (
      <i
        className="devicon-postgresql-plain"
        key={"devicon-postgresql-plain"}
        alt="PostgreSQL"
      ></i>
    ),
    mongodb: (
      <i
        className="devicon-mongodb-plain"
        key={"devicon-mongodb-plain"}
        alt="MongoDB"
      ></i>
    ),
    mariadb: (
      <i
        className="devicon-mariadb-original colored"
        key="devicon-mariadb-original-wordmark"
        alt="MariaDB"
      ></i>
    ),
    firebase: (
      <i
        className="devicon-firebase-plain colored"
        key="devicon-firebase-plain-wordmark colored"
        alt="Firebase"
      ></i>
    ),

    // -------------------- Design --------------------

    bootstrap: (
      <i
        className="devicon-bootstrap-plain colored"
        key="devicon-bootstrap-plain colored"
        alt="Bootstrap"
      ></i>
    ),
    sass: (
      <i
        className="devicon-sass-original colored"
        key="devicon-sass-original colored"
        alt="Sass"
      ></i>
    ),
    primereact: (
      <img
        src={PrimeReact}
        className="stacks-imgs"
        alt="PrimeReact"
        key="primereact"
      />
    ),

    // -------------------- Autre Outils --------------------

    ansible: (
      <i className="devicon-ansible-plain" key="devicon-ansible-plain"></i>
    ),
    docker: (
      <i
        className="devicon-docker-plain colored"
        key="devicon-docker-plain colored"
        alt="Docker"
      ></i>
    ),
    prometheus: (
      <i
        className="devicon-prometheus-original colored"
        key="devicon-prometheus-plain-wordmark colored"
        alt="Prometheus"
      ></i>
    ),
    grafana: (
      <i
        className="devicon-grafana-plain colored"
        key="devicon-grafana-plain-wordmark colored"
        alt="Grafana"
      ></i>
    ),
    // -------------------- Libs Autre --------------------

    pytorch: (
      <i
        className="devicon-pytorch-original colored"
        key={"devicon-pytorch-original"}
        alt="PyTorch"
      ></i>
    ),
    jupyter: (
      <i
        className="devicon-jupyter-plain-wordmark colored"
        key="devicon-jupyter-plain-wordmark colored"
        alt="Jupyter"
      ></i>
    ),
    threejs: (
      <i
        className="devicon-threejs-original-wordmark"
        key="devicon-threejs-original-wordmark"
        alt="ThreeJS"
      ></i>
    ),
  },
  langues: {
    FR: {
      code: "FR",
      icon: <FR title="Français" className="flags" />,
    },
    EN: {
      code: "EN",
      icon: <US title="English" className="flags" />,
    },
    ES: {
      code: "ES",
      icon: <ES title="Español" className="flags" />,
    },
    IT: {
      code: "IT",
      icon: <IT title="Italiano" className="flags" />,
    },
  },

  interets: {
    avionGif: avionGif,
    htmlGif: htmlGif,
    motoGif: motoGif,
    printerGif: printerGif,
    equipementGif: equipementGif,
  },

  stacks: {
    vscode: {
      name: "vscode",
      image: vscode,
    },
    visualstudio: {
      name: "visualstudio",
      image: visualstudio,
    },
    trello: {
      name: "trello",
      image: trello,
    },
    zapier: {
      name: "zapier",
      image: zapier,
    },
    slack: {
      name: "slack",
      image: slack,
    },
    figma: {
      name: "figma",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg",
    },
    photoshop: {
      name: "photoshop",
      image: photoshop,
    },
    illustrator: {
      name: "illustrator",
      image: illustrator,
    },
    indesign: {
      name: "indesign",
      image: indesign,
    },
    premierepro: {
      name: "premierepro",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/premierepro/premierepro-original.svg",
    },
    aftereffects: {
      name: "aftereffects",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/aftereffects/aftereffects-original.svg",
    },
    fusion: {
      name: "fusion",
      image: fusion,
    },
    office: {
      name: "office",
      image: office,
    },
    googlesuite: {
      name: "googlesuite",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/google/google-original.svg",
    },
    analytics: {
      name: "analytics",
      image: analytics,
    },
    proxmox: {
      name: "proxmox",
      image: proxmox,
    },
    portainer: {
      name: "portainer",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/portainer/portainer-original-wordmark.svg",
    },
    swagger: {
      name: "swagger",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/swagger/swagger-original-wordmark.svg",
    },
    sentry: {
      name: "sentry",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/sentry/sentry-original-wordmark.svg",
    },
    atera: {
      name: "atera",
      image: atera,
    },
    teamviewer: {
      name: "teamviewer",
      image: teamviewer,
    },
    mailjet: {
      name: "mailjet",
      image: mailjet,
    },
    grafana: {
      name: "grafana",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/grafana/grafana-original-wordmark.svg",
    },
    prometheus: {
      name: "prometheus",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/prometheus/prometheus-plain-wordmark.svg",
    },
    linux: {
      name: "linux",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linux/linux-original.svg",
    },
    powershell: {
      name: "powershell",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/powershell/powershell-original.svg",
    },
    html: {
      name: "html",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original-wordmark.svg",
    },
    css: {
      name: "css",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg",
    },
    sass: {
      name: "sass",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg",
    },
    javascript: {
      name: "javascript",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
    },
    nodejs: {
      name: "nodejs",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nodejs/nodejs-plain-wordmark.svg",
    },
    python: {
      name: "python",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original-wordmark.svg",
    },
    java: {
      name: "java",
      categories: ["App", "Back"],
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original-wordmark.svg",
    },
    mysql: {
      name: "mysql",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mysql/mysql-original-wordmark.svg",
    },
    mongodb: {
      name: "mongodb",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mongodb/mongodb-plain-wordmark.svg",
    },
    "c#": {
      name: "c#",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/csharp/csharp-plain.svg",
    },
    bootstrap: {
      name: "bootstrap",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original-wordmark.svg",
    },
    primereact: {
      name: "primereact",
      categories: ["Web", "Design"],
      image: PrimeReact,
    },
    reactjs: {
      name: "reactjs",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original-wordmark.svg",
    },
    expressjs: {
      name: "expressjs",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg",
    },
    vuejs: {
      name: "vuejs",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vuejs/vuejs-original-wordmark.svg",
    },
    flutter: {
      name: "flutter",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flutter/flutter-original.svg",
    },
    pytorch: {
      name: "pytorch",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/pytorch/pytorch-original-wordmark.svg",
    },
    ".net": {
      name: ".net",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/dot-net/dot-net-original-wordmark.svg",
    },
    unity: {
      name: "unity",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/unity/unity-original-wordmark.svg",
    },
    git: {
      name: "git",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original-wordmark.svg",
    },
    githubactions: {
      name: "githubactions",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/githubactions/githubactions-original.svg",
    },
    docker: {
      name: "docker",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original-wordmark.svg",
    },
    wordpress: {
      name: "wordpress",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/wordpress/wordpress-original.svg",
    },
    anaconda: {
      name: "anaconda",
      image:
        "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/anaconda/anaconda-original-wordmark.svg",
    },
  },
  projects: {
    AppLisaGestion: {
      name: "AppLisaGestion",
      img: AppLisaGestion,
    },
    portfolio: {
      name: "portfolio",
      img: portfolio,
    },
    pictMan0: {
      name: "pictMan0",
      img: pictMan0,
    },
    pictMan1: {
      name: "pictMan1",
      img: pictMan1,
    },
    pictMan1L: {
      name: "pictMan1L",
      img: pictMan1L,
    },
    adaptia1: {
      name: "adaptia1",
      img: adaptia1,
    },
    adaptia1L: {
      name: "adaptia1L",
      img: adaptia1L,
    },
  },
  icons: {
    perso: null,
    study: null,
    work: null,
    mobile: <Phone style={{ marginLeft: "5px" }} />,
    web: <Globe2 style={{ marginLeft: "5px" }} />,
    // logiciel: <PcDisplay style={{ marginLeft: "5px" }} />,
    logiciel: <Laptop style={{ marginLeft: "5px" }} />,
  },
};

export { customIcons };
