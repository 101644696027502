import "../style/darkmode.scss";
import React from "react";
import logo from "../assets/cropped-R-rb-xl-4.png";
import { Link, useLocation } from "react-router-dom";
import DarkMode from "./DarkMode";
import BurgerMenu from "./burger";
import { router } from "../config/router";
import i18n from "../config/i18n";
import { useTranslation } from "react-i18next";
import { customIcons } from "../config/icons";

function Header() {
  const { t } = useTranslation();
  const location = useLocation();

  function routerNav() {
    return (
      <>
        {router.map((item, index) => {
          return (
            <span key={`sp${index}`} className="menu-item">
              <Link
                to={item.url}
                className={`menu-item ${
                  location.pathname === item.url ? "active" : ""
                }`}
                key={`li${index}`}
              >
                {item.icon} {item.name ? t(`pages.${item.name}.nav`) : <></>}
              </Link>
            </span>
          );
        })}
        <div id="themes-div" key={"thd-1"}>
          <DarkMode />
          <div
            id="translate-icone"
            className="zoom-in-hard"
            onClick={() =>
              i18n.changeLanguage(i18n.language === "fr" ? "en" : "fr")
            }
          >
            {customIcons.langues[i18n.language.toUpperCase()]?.icon}
            {i18n.language === "fr"
              ? customIcons.langues["EN"]?.icon
              : customIcons.langues["FR"]?.icon}
          </div>
        </div>
        <BurgerMenu />
      </>
    );
  }

  return (
    <header className="App-header">
      <span className="img-span">
        <Link key="-1" to="/">
          <img src={logo} className="App-logo" alt="logo" />
        </Link>
      </span>

      <nav>{routerNav()}</nav>
    </header>
  );
}

export default Header;
