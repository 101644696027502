import { HouseFill } from "react-bootstrap-icons";
import Home from "../pages/Home";
import About2 from "../pages/About";
import Projects2 from "../pages/Projects";
import Stacks2 from "../pages/Stacks";

export const router = [
  {
    name: "home",
    url: "/",
    component: <Home />,
    title: "Home",
    text: "",
    icon: <HouseFill size={20} />,
  },
  {
    name: "about",
    url: "/about",
    component: <About2 />,
    title: "About",
    text: "ABOUT",
    icon: null,
  },
  {
    name: "projects",
    url: "/projects",
    component: <Projects2 />,
    title: "Projects",
    text: "PROJECTS",
    icon: null,
  },
  {
    name: "stacks",
    url: "/stacks",
    component: <Stacks2 />,
    title: "Stacks",
    text: "STACKS",
    icon: null,
  },
  //   {
  // name: "contact",
  //     url: "/contact",
  //     component: <Contact />,
  //     title: "Contact",
  //     text: "CONTACT",
  //     icon: null,
  //   },
];
