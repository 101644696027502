import React, { useEffect, useState } from "react";
import { Github, Linkedin, EnvelopeAtFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import i18n from "../config/i18n";
import GA4utils from "../services/GA4";

function Footer() {
  const { t } = useTranslation();
  const [txtCredits, setTxtCredits] = useState("Propulsé par ");
  const frenchCredits = () => setTxtCredits("Propulsé par ");
  const otherCredits = () => setTxtCredits("Powered by ");
  const base64Email = btoa("richard@rbruzzo.fr");

  const getEmail = () => {
    return `mailto:${atob(base64Email)}`;
  };

  const GetEmail = (e) => {
    e.preventDefault();
    window.location.href = getEmail();
    GA4utils.handleClick("email");
  };

  const GetLinkedin = (e) => {
    GA4utils.handleClick("linkedin");
  };

  useEffect(() => {
    i18n.language === "fr" ? frenchCredits() : otherCredits();
  }, [i18n.language, t]);

  return (
    <footer>
      <p>
        {txtCredits}
        <a
          href="https://www.linkedin.com/in/richard-bruzzo-9a050485/"
          target="_blank"
          className="hover-underline-animation"
          aria-label="Linkedin Richard Bruzzo"
        >
          Richard Bruzzo
        </a>
      </p>
      <span>
        <a href="#" onClick={GetEmail} aria-label="Email Richard Bruzzo">
          <EnvelopeAtFill color="white" size={22} />
        </a>
        <a
          href="https://www.linkedin.com/in/richard-bruzzo-9a050485/"
          target="_blank"
          alt="Linkedin"
          aria-label="Linkedin Richard Bruzzo"
        >
          <Linkedin color="white" size={22} onClick={GetLinkedin} />
        </a>
        {/* <a>
           <Github color="white" size={22} />
        </a> */}
      </span>
    </footer>
  );
}

export default Footer;
